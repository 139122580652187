import React, { useState, useEffect } from "react";
import {
  Form,
  Card,
  Col,
  ToggleButtonGroup,
  ToggleButton,
  HelperText,
} from "@deere/ux.uxframe-react";
import { Controller } from "react-hook-form";

import VettedSuppliersNotIncluded from "./VettedSuppliersNotIncluded";
export default function SDSConsidered(props) {
  const {
    reasonCodes,
    sdfInfo,
    initialSdsConsidered,
    isDirty,
    sdiSuppliers,
    sdiMasterList,
    readOnly,
  } = props;
  const [sdsConsidered, setSdsConsidered] = useState(initialSdsConsidered);

  const { register, control, errors, reset, clearErrors, getValues } =
    props.commonProps;
  const noOptionDisabled = readOnly
    ? readOnly
    : sdiSuppliers && sdiSuppliers.size > 0;

  useEffect(() => {
    clearErrors(["reasonCode", "comment"]);
    reset(
      {
        POorSANumber: sdfInfo && sdfInfo.nonStrategyFormPONumber,
        sdsConsidered: initialSdsConsidered,
        reasonCode: sdfInfo && sdfInfo.sdsNotConsideredReasonCode,
        comment: sdfInfo && sdfInfo.sdsConsideredComments,
        items:
          sdfInfo && sdfInfo.vettedSuppliers ? sdfInfo.vettedSuppliers : [],
      },
      {
        errors: false,
        dirtyFields: false,
        isDirty: isDirty,
        isSubmitted: false,
        touched: false,
        isValid: false,
        submitCount: false,
      }
    );
    setSdsConsidered(initialSdsConsidered);
  }, [sdfInfo, initialSdsConsidered]);

  return (
    <>
      <Card>
        <Card.Body>
          <Form.Row>
            <Form.Group as={Col} xs={8} md={25}>
              <Form.Label className="uxf-label">
                Did you consider Small and/or Diverse Suppliers?{" "}
                <span aria-label="required field" className="uxf-is-required">
                  *
                </span>
              </Form.Label>

              <Controller
                as={
                  <ToggleButtonGroup
                    role="toolbar"
                    className="mt-3"
                    name="radioButtonGroup1"
                    type="radio"
                    value={sdsConsidered}
                    ariaLabel="Did you consider Small and/or Diverse Suppliers?"
                  >
                    <ToggleButton
                      value="Yes"
                      id="Yes"
                      onClick={(e) =>
                        !noOptionDisabled && setSdsConsidered(e.target.value)
                      }
                      disabled={readOnly && !sdfInfo.sdsConsidered}
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      value="No"
                      id="No"
                      disabled={
                        readOnly ? sdfInfo.sdsConsidered : noOptionDisabled
                      }
                      onClick={(e) =>
                        !noOptionDisabled && setSdsConsidered(e.target.value)
                      }
                    >
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                }
                name="sdsConsidered"
                control={control}
                defaultValue={sdsConsidered}
              />
            </Form.Group>
          </Form.Row>

          {sdsConsidered === "No" && reasonCodes && (
            <Form.Row>
              <Form.Group as={Col} xs={15} md={25}>
                <Form.Label className="uxf-label">
                  Reason for why you didn't consider Small and/or Diverse
                  Suppliers?{" "}
                  <span aria-label="required field" className="uxf-is-required">
                    *
                  </span>
                </Form.Label>
                {readOnly ? (
                  <span>
                    <br />{" "}
                    {reasonCodes.map((option) => {
                      if (
                        option.code.toString() ===
                        sdfInfo.sdsNotConsideredReasonCode
                      )
                        return option.name;
                    })}
                  </span>
                ) : (
                  <Form.Control
                    as="select"
                    className="custom-select"
                    ref={register}
                    name="reasonCode"
                  >
                    <option key={"Select"}></option>
                    {reasonCodes.map((option) => (
                      <option key={option.code} value={option.code}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Control>
                )}
                {errors.reasonCode && (
                  <div>
                    <HelperText
                      helperText=""
                      helperTextId=""
                      className="uxf-is-required"
                      style={{ fontSize: "18px" }}
                    >
                      {errors.reasonCode.message}
                    </HelperText>
                  </div>
                )}
              </Form.Group>
            </Form.Row>
          )}
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="uxf-label">
                Comments{" "}
                {getValues().reasonCode === "11" && (
                  <span aria-label="required field" className="uxf-is-required">
                    *
                  </span>
                )}
              </Form.Label>
              {readOnly ? (
                <span>
                  <br />
                  {sdfInfo && sdfInfo.sdsConsideredComments}
                </span>
              ) : (
                <Form.Control
                  as="textarea"
                  aria-label="Comment"
                  ref={register}
                  maxLength="512"
                  name="comment"
                  onBlur={(e) => (e.target.value = e.target.value.trim())}
                />
              )}
              {errors && errors.comment && (
                <div>
                  <HelperText
                    helperText=""
                    helperTextId=""
                    className="uxf-is-required"
                    style={{ fontSize: "18px" }}
                  >
                    {errors.comment.message}
                  </HelperText>
                </div>
              )}
            </Form.Group>
          </Form.Row>
        </Card.Body>
      </Card>
      <br />
      {!readOnly && sdsConsidered === "Yes" && (
        <VettedSuppliersNotIncluded
          sdfInfo={sdfInfo}
          suppliersMap={props.suppliersMap}
          commonProps={props.commonProps}
          prospectingTool={props.prospectingTool}
          reasonCodes={reasonCodes}
          sdiSuppliers={sdiSuppliers}
          sdiMasterList={sdiMasterList}
        />
      )}
    </>
  );
}
