import { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Form, Col, Row, Button } from "@deere/ux.uxframe-react";
import {
  callOwnerInfoAPI,
  callSdfAPI,
  callStrategiesAPI,
  getUpCaseOwnerId,
  windowClose,
} from "../shared/SDFHelper";
import SMModal from "../shared/SMModal";
import { useAPIProgress } from "../shared/APIProgress";
import { useStatusMessages } from "../shared/StatusMessages";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";
import { useUserInfo } from "../shared/UserInfo";
import NoAccessEmployee from "./NoAccessEmployee";
import Suppliers from "./Suppliers";
import GetProspectingAndReasonCodes from "./GetProspectingAndReasonCodes";
import GetSDIMasterList from "./GetSDIMasterList";
import MessageBanner from "../shared/MessageBanner";
import SDSConsidered from "./SDSConsidered";
import {
  DIRECT_STRATEGY_MANAGEMENT,
  FORM_TYPE_IN_STRATEGY,
  GLOBAL_STRATEGIC_SUPPLIER,
  INDIRECT_STRATEGY_MANAGEMENT,
  NO,
  YES,
} from "../shared/AppConstants";

import VettedSupplierViewOnly from "./VettedSupplierViewOnly";
import { addPropAndValue, isFormNotGoodForSave } from "../shared/UtilFunctions";
import SMIndirectStrategy from "./SMIndirectStrategy";
import GSSStrategy from "./GSSStrategy";

export default function StrategySDFormView() {
  let dateFormat = require("dateformat");
  let formatString = "dd-mmm-yyyy";
  const info = useUserInfo();
  const tokenAvailable = info && info.token;
  const loggedInUser = info && info.userId;
  const [strategyOwnerInfo, setStrategyOwnerInfo] = useState(null);
  const [sdfUser, setSdfUser] = useState(null);
  const [strategyInfo, setStrategyInfo] = useState(null);
  const [sdfInfo, setSdfInfo] = useState(null);
  const [sdiSuppliers, setSdiSuppliers] = useState();
  const [sdiMasterList, setSdiMasterList] = useState(null);
  const [prospectingTool, setProspectingTool] = useState(null);
  const [reasonCodes, setReasonCodes] = useState(null);
  const { strategyId, sdfId, appName } = useParams();
  const [suppliersMap, setSuppliersMap] = useState(null);
  const [strategyNumber] = useState(strategyId ? strategyId : 0);
  const {
    statusMessages,
    clearAllMessages,
    setStatusSuccessMsg,
    setStatusErrorMsg,
  } = useStatusMessages();

  const history = useHistory();
  const [sdfNumber] = useState(sdfId ? parseInt(sdfId) : 0);
  const [recertifyDisabled, setRecertifyDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [formType] = useState(strategyId ? FORM_TYPE_IN_STRATEGY : "");

  const [hasAccess, setHasAccess] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [strategicSuppliers, setStrategicSuppliers] = useState(null);
  const [applicationName] = useState(
    appName ? appName : GLOBAL_STRATEGIC_SUPPLIER
  );

  const { apiInProgressCount, incrementAPIInProgress, decrementAPIInProgress } =
    useAPIProgress();

  const { getValues, reset, control, setError, errors, setValue, clearErrors } =
    useForm({
      mode: "all",
    });

  const commonProps = {
    tokenAvailable: tokenAvailable,
    setStatusErrorMsg: setStatusErrorMsg,
    getValues: getValues,
    incrementAPIInProgress: incrementAPIInProgress,
    decrementAPIInProgress: decrementAPIInProgress,
    control: control,
    setValue: setValue,
    errors: errors,
    reset: reset,
    clearErrors: clearErrors,
    setError: setError,
  };

  function loadGSSStrategyOwnerInfo(data) {
    let strategyOwnerId =
      applicationName === DIRECT_STRATEGY_MANAGEMENT && data.ownerId
        ? data.ownerId
        : data.strategyOwnerId && data.strategyOwnerId !== ""
        ? data.strategyOwnerId
        : null;

    strategyOwnerId &&
      callOwnerInfoAPI(
        strategyOwnerId,
        setStrategyOwnerInfo,
        incrementAPIInProgress,
        decrementAPIInProgress,
        setStatusErrorMsg
      );
  }

  function recertifyStrategy() {
    clearAllMessages();
    setShowModal(true);
  }

  function cancelStrategy() {
    windowClose(history);
  }

  useEffect(() => {
    if (tokenAvailable && strategyNumber > 0)
      callStrategiesAPI(
        applicationName,
        strategyNumber,
        sdfNumber,
        setStrategyInfo,
        setStrategicSuppliers,
        incrementAPIInProgress,
        decrementAPIInProgress,
        setStatusErrorMsg
      );
  }, [strategyNumber, applicationName, sdfNumber, tokenAvailable]);

  useEffect(() => {
    if (strategyInfo) {
      loadGSSStrategyOwnerInfo(strategyInfo);
      validateAndSetAccess();
    }
  }, [strategyInfo, sdfInfo]);

  function validateAndSetAccess() {
    let hasAccess = false;

    if (info && info.hasNonStrategySDFAccess)
      hasAccess = sdfNumber === strategyInfo.sdsFormId;

    setHasAccess(hasAccess ? YES : NO);

    if (
      loggedInUser.toUpperCase() ===
      getUpCaseOwnerId(applicationName, strategyInfo)
    )
      setIsEdit(true);
  }

  useEffect(() => {
    if (tokenAvailable && sdfNumber > 0)
      callSdfAPI(
        sdfNumber,
        setSdfUser,
        setSdfInfo,
        setSuppliersMap,
        incrementAPIInProgress,
        decrementAPIInProgress,
        setStatusErrorMsg
      );
  }, [sdfNumber, tokenAvailable]);

  useEffect(() => {
    if (sdfInfo && strategyInfo) {
      let isDisabled = true;
      let data = {
        ...sdfInfo,
      };

      addPropAndValue(data, "comment", sdfInfo.sdsConsideredComments);
      addPropAndValue(data, "reasonCode", sdfInfo.sdsNotConsideredReasonCode);
      addPropAndValue(
        data,
        "sdsConsidered",
        sdfInfo.sdsConsidered === true ? "Yes" : "No"
      );
      let items = [...sdfInfo.vettedSuppliers];
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        addPropAndValue(item, "vettedProspectingTool", item.prospectingTool);
        addPropAndValue(item, "vettedReasonCode", item.reasonCode);
        addPropAndValue(item, "vettedSupplierComment", item.comments);
        addPropAndValue(item, "suppliers", item.nameOrNumber);
        addPropAndValue(item, "sdis", item.vettedProspectiveSupplierSDIS);
      }
      addPropAndValue(data, "items", items);
      isDisabled = isFormNotGoodForSave(data, sdiSuppliers);
      if (
        data.sdsConsidered === "No" &&
        sdiSuppliers &&
        sdiSuppliers.size > 0
      ) {
        isDisabled = true;
      }
      if (
        data.sdsConsidered === "Yes" &&
        !sdiSuppliers &&
        sdfInfo.vettedSuppliers.length === 0
      ) {
        isDisabled = true;
      }
      setRecertifyDisabled(isDisabled);
    }
  }, [sdfInfo, sdiSuppliers, strategyInfo]);

  function recertifyStrategySave() {
    save();
    setShowModal(false);
  }

  function save() {
    clearAllMessages();

    let url = process.env.REACT_APP_SDF_API_URL + "/" + sdfNumber;
    addPropAndValue(sdfInfo, "strategyId", strategyNumber);

    fetchJsonWithAuth(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(sdfInfo),
    })
      .then((response) => {
        const result = response.json;
        const { formId } = result;

        if (result.hasOwnProperty("400")) setStatusErrorMsg(result["400"]);
        else if (formId) {
          setStatusSuccessMsg("SDF recertified successfully");
          setSdfInfo(result);

          // Call Parent with SDF #
          if (window.opener) {
            let url = process.env.REACT_APP_GSS_BASE_URL;

            if (applicationName === INDIRECT_STRATEGY_MANAGEMENT) {
              url = process.env.REACT_APP_SM_BASE_URL;
            } else if (applicationName === DIRECT_STRATEGY_MANAGEMENT) {
              url = process.env.REACT_APP_DMSM_BASE_URL;
            }
            window.opener.postMessage(result.formId, url);
          }
        }
      })
      .catch((error) =>
        setStatusErrorMsg(
          "Couldn't recertify the SD Form. Please try again.",
          error
        )
      );
  }

  return (!strategyInfo || !sdfInfo) &&
    statusMessages &&
    statusMessages.error ? (
    <MessageBanner
      statusMessages={statusMessages}
      apiInProgressCount={apiInProgressCount}
    />
  ) : (
    info &&
      strategyInfo &&
      hasAccess &&
      (hasAccess === NO ? (
        <NoAccessEmployee userId={loggedInUser} />
      ) : (
        <main id="content" className="container-fluid pb-5 bg-gray">
          <Form>
            <div className="container-fluid pb-2 bg-gray position-sticky fixed-top">
              <Row className="mt-3">
                <Col>
                  <h2 className="mt-2 float-left">
                    Sourcing Diversity Form # {sdfNumber}
                  </h2>
                </Col>
                <Col>
                  <div className="mt-2 float-right">
                    <Button variant="secondary" onClick={cancelStrategy}>
                      Close
                    </Button>
                    {isEdit && (
                      <>
                        {!recertifyDisabled && (
                          <Button
                            variant="outline-dark"
                            className="float-center btn-outline-uxf-green"
                            onClick={recertifyStrategy}
                          >
                            Recertify
                          </Button>
                        )}
                        <Link
                          to={{
                            pathname:
                              "/editSDForm/" +
                              sdfNumber +
                              "/" +
                              applicationName +
                              "/" +
                              strategyId,
                          }}
                          className="btn btn-primary"
                          title="Select Edit to make changes to vetted suppliers or comments."
                        >
                          Edit
                        </Link>
                      </>
                    )}
                  </div>
                </Col>
              </Row>

              {sdfNumber > 0 && (
                <h6>
                  Last updated on{" "}
                  {sdfInfo &&
                    dateFormat(new Date(sdfInfo.updatedOn), formatString)}{" "}
                  by {sdfUser ? sdfUser : sdfInfo && sdfInfo.updatedById}
                </h6>
              )}
              <br />
              <MessageBanner
                statusMessages={statusMessages}
                apiInProgressCount={apiInProgressCount}
              />
            </div>

            <GetProspectingAndReasonCodes
              commonProps={commonProps}
              setReasonCodes={setReasonCodes}
              setProspectingTool={setProspectingTool}
            />
            <GetSDIMasterList
              commonProps={commonProps}
              setSdiMasterList={setSdiMasterList}
            />
            <Row className="mt-3">
              <Col>
                {applicationName === INDIRECT_STRATEGY_MANAGEMENT ? (
                  <SMIndirectStrategy
                    formType={formType}
                    strategyInfo={strategyInfo}
                    applicationName={applicationName}
                    commonProps={commonProps}
                  />
                ) : (
                  <GSSStrategy
                    formType={formType}
                    strategyInfo={strategyInfo}
                    applicationName={applicationName}
                    strategyOwnerInfo={strategyOwnerInfo}
                    commonProps={commonProps}
                  />
                )}
                {strategyInfo && (
                  <>
                    <br />
                    {sdiMasterList && (
                      <Row>
                        <Col md="24">
                          <Suppliers
                            commonProps={commonProps}
                            preferredSuppliers={strategicSuppliers}
                            sdiSuppliers={sdiSuppliers}
                            setSdiSuppliersForParent={setSdiSuppliers}
                            sdiMasterList={sdiMasterList}
                            noAlert={true}
                          />
                        </Col>
                      </Row>
                    )}
                    <br />
                    {sdfInfo && (
                      <Row>
                        <Col md="24">
                          <SDSConsidered
                            commonProps={commonProps}
                            reasonCodes={reasonCodes}
                            suppliersMap={suppliersMap}
                            prospectingTool={prospectingTool}
                            sdfInfo={sdfInfo}
                            sdiSuppliers={sdiSuppliers}
                            initialSdsConsidered={
                              sdfInfo.sdsConsidered ? "Yes" : "No"
                            }
                            sdiMasterList={sdiMasterList}
                            readOnly={true}
                          />
                        </Col>
                      </Row>
                    )}
                    {sdfInfo &&
                      sdfInfo.vettedSuppliers &&
                      sdfInfo.vettedSuppliers.length > 0 && (
                        <>
                          <h4>Vetted Suppliers Who Were Not Included</h4>
                          <Row>
                            <Col md="24">
                              {sdfInfo.vettedSuppliers.map((item) => (
                                <VettedSupplierViewOnly
                                  key={item.supplierId}
                                  vettedSupplier={item}
                                  suppliersMap={suppliersMap}
                                  prospectingTool={prospectingTool}
                                  reasonCodes={reasonCodes}
                                />
                              ))}
                            </Col>
                          </Row>
                        </>
                      )}
                  </>
                )}
              </Col>
            </Row>
          </Form>
          <SMModal
            showModal={showModal}
            handleCancel={() => setShowModal(false)}
            handleYes={() => recertifyStrategySave()}
            title="Recertify"
            msg_line1="By selecting Recertify, you are acknowledging that you have reviewed the form and no changes are required."
            msg_line2="If you have evaluated additional prospective small and/or diverse suppliers, please cancel out of this window and click Edit on the form instead."
            cancelText="Cancel"
            yesText="Recertify"
            information={true}
          />
        </main>
      ))
  );
}
