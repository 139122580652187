import React from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  Spinner,
  Header,
  HeaderButtonBack,
  Dropdown,
  HeaderContext,
  Badge,
} from "@deere/ux.uxframe-react";

import { ReactComponent as HomeIcon } from "@deere/ux.brand-foundations/icons/home.svg";

import { useUserLogin } from "../shared/UserLogin";
import createGlobalState from "global-react-state";
import { getContactUsLink } from "../shared/UtilFunctions";

export const [useLoginState, setLoginState, getLoginState] = createGlobalState(
  {}
);

class UxfCustomNavbarReactRouter extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  static contextType = HeaderContext;

  handleClick() {
    this.context.onClickLink();
  }

  render() {
    return (
      <>
        <React.Fragment>
          <Nav.Item className="uxf-nav-item-icon">
            <a
              href="http://jdsn.deere.com"
              className="nav-icon"
              aria-label="home page"
            >
              <HomeIcon className="nav-home-icon" />
            </a>
          </Nav.Item>
          <Dropdown as={Nav.Item} className="active">
            <Dropdown.Toggle
              className="nav-link"
              as={Link}
              id="navbarDropdown1"
              onClick={this.handleClick}
              to="/"
            >
              <span className="uxf-link-text">Sourcing Diversity Form</span>
            </Dropdown.Toggle>
            {this.props.roles && this.props.roles.length > 0 && (
              <Dropdown.Menu renderOnMount style={{ zIndex: "9999" }}>
                <HeaderButtonBack>Menu</HeaderButtonBack>
                <Dropdown.Item
                  as={Link}
                  onClick={this.handleClick}
                  to="/createSDForm"
                >
                  Create Sourcing Diversity Form
                </Dropdown.Item>
                <Dropdown.Item as={Link} onClick={this.handleClick} to="/">
                  Search Sourcing Diversity Form
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
          <Nav.Item>
            <Nav.Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://jdsn.deere.com/wps/myportal/jdsn/JDSN/Applications"
            >
              <span className="uxf-link-text">Applications</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://jdsn.deere.com/wps/myportal/jdsn/JDSN/App?current=true&urile=wcm:path:/jdsn_new/applications/SDF"
            >
              <span className="uxf-link-text">Help</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              target="_blank"
              rel="noopener noreferrer"
              href={getContactUsLink(this.props.userId)}
            >
              <span className="uxf-link-text">Contact Us</span>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className="uxf-nav-item-widget pt-2">
            <h4>
              <Badge variant="warning" className="ml-2 mr-2">
                {this.props.message}
              </Badge>
            </h4>
          </Nav.Item>
        </React.Fragment>
      </>
    );
  }
}

function searchJDSN(e) {
  let searchText = e.target.uxfsearchinput.value;
  window.open(
    "https://jdsn.deere.com/wps/myportal/jdsn/Home/SupplierSearch?sources=" +
      escape(searchText),
    "JDSN Search"
  );
  e.preventDefault();
}

export default function SDFormHeader() {
  const [userInfo, logout] = useUserLogin();
  const [loginState] = useLoginState();
  const roles = loginState.roles;
  const userId = loginState.userId;

  const HeaderDropdown = (
    <Dropdown alignRight>
      <Dropdown.Toggle variant="link" size="sm" aria-label="account menu">
        Hi,{" "}
        <span className="uxf-header-title-username">
          {userInfo && userInfo.given_name}
        </span>{" "}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={logout}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <>
      {userInfo && (
        <Header
          inputProps={{ ariaLabel: "Search", name: "uxfsearchinput" }}
          submitBtnProps={{ ariaLabel: "Search" }}
          formProps={{ onSubmit: searchJDSN }}
          clearBtnProps={{ ariaLabel: "Clear search" }}
          logoLink="http://deere.com"
          headerTitleComponent={HeaderDropdown}
          skipLink="#content"
          pageHeading="Supply Network"
          pageHeadingLink="http://jdsn.deere.com"
        >
          <UxfCustomNavbarReactRouter
            roles={roles}
            message={loginState.message}
            userId={userId}
          />
        </Header>
      )}
      {userInfo === null && (
        <div className="d-flex w-100 justify-content-center align-items-center">
          <Spinner className="uxf-spinner-border-lg" animation="border" />
          <p className="uxf-spinner-border-inline-text">Please Wait...</p>
        </div>
      )}
    </>
  );
}
