import React, { useEffect, useState } from "react";
import { Pagination, List, Dropdown } from "@deere/ux.uxframe-react";
import { MAX_RESULT_PER_PAGE, MAX_VISIBLE_PAGES } from "../shared/AppConstants";

export default function SDFormWithSortandPagination(props) {
  const { list } = props;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(list.length);
  const [perPage, setPerPage] = useState(MAX_RESULT_PER_PAGE);
  const [selectedOption, setSelectedOption] = useState("SDF Number 0-9");
  const [listToshow, setListToshow] = useState(list);

  useEffect(() => {
    setListToshow(list);
    setTotal(list.length);
    setPage(1);
  }, [list]);

  useEffect(() => {
    const sortedArray = [...listToshow];
    if (selectedOption === "SDF Number 0-9") {
      sortedArray.sort(function (x, y) {
        return x.props.sdform.formId - y.props.sdform.formId;
      });
    } else if (selectedOption === "SDF Number 9-0") {
      sortedArray.sort(function (x, y) {
        return y.props.sdform.formId - x.props.sdform.formId;
      });
    }
    setListToshow(sortedArray);
    setPage(1); // Resetting page to 1
  }, [selectedOption]);

  function updatePage(page) {
    setPage(page);
    window.scrollTo(0, 0);
  }

  const nextPage = () => {
    const max = Math.ceil(total / perPage);

    if (page < max) {
      updatePage(page + 1);
    }
    window.scrollTo(0, 0);
  };

  const previousPage = () => {
    if (page > 1) {
      updatePage(page - 1);
    }
    window.scrollTo(0, 0);
  };

  const pageItem = (paginationPage, pageDisplay) => {
    let pageDisplayActual = pageDisplay;

    if (pageDisplay === undefined) {
      pageDisplayActual = paginationPage;
    }

    return (
      <Pagination.Item
        active={paginationPage === page}
        key={paginationPage}
        onClick={() => updatePage(paginationPage)}
      >
        {pageDisplayActual}
      </Pagination.Item>
    );
  };

  function pageItems() {
    const lastPage = Math.ceil(total / perPage);
    const pageItems = [pageItem(page)];
    let delta = 1;
    let addedPages = 1;
    let visiblePages = 1;
    let firstPageAdded = page;
    let lastPageAdded = page;

    // add pages around the current page until we run out
    while (
      visiblePages < MAX_VISIBLE_PAGES &&
      visiblePages < lastPage &&
      addedPages > 0
    ) {
      addedPages = 0;

      if (page - delta > 0) {
        pageItems.unshift(pageItem(page - delta));
        firstPageAdded = page - delta;
        addedPages += 1;
      }
      if (page + delta <= lastPage) {
        pageItems.push(pageItem(page + delta));
        lastPageAdded = page + delta;
        addedPages += 1;
      }

      visiblePages += addedPages;
      delta += 1;
    }
    // if we ran out of pages when rendering them then we need to show the first and last page
    if (visiblePages !== lastPage) {
      if (firstPageAdded !== 1) {
        pageItems.shift();
        pageItems.unshift(pageItem(1, "1..."));
      }
      if (lastPageAdded !== lastPage) {
        pageItems.pop();
        pageItems.push(pageItem(lastPage, `...${lastPage}`));
      }
    }

    return pageItems;
  }

  function currentPageResultsStart() {
    return (page - 1) * perPage + 1;
  }

  function currentPageResultsEnd() {
    const start = currentPageResultsStart();
    const end = start + perPage - 1;

    return end < total ? end : total;
  }

  const lastPage = Math.ceil(total / perPage);

  return (
    <div>
      {total > 0 && (
        <div className="d-flex w-100 justify-content-between">
          <div style={{ position: "relative", top: "7px" }}>
            {currentPageResultsStart()} - {currentPageResultsEnd()} of {total}{" "}
            Results
          </div>
          <span className="font-weight-bold">
            <Dropdown>
              <span style={{ position: "relative", top: "2px" }}>Sort by</span>
              <Dropdown.Toggle variant="link" id="dropdown-basic-3">
                {selectedOption}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setSelectedOption("SDF Number 0-9");
                  }}
                >
                  SDF Number 0-9
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSelectedOption("SDF Number 9-0");
                  }}
                >
                  SDF Number 9-0
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
      )}

      <List className="list-group">
        {listToshow.slice((page - 1) * perPage, (page - 1) * perPage + perPage)}
      </List>

      {total > MAX_RESULT_PER_PAGE && (
        <Pagination
          ariaLabel="Page navigation example"
          pageResults={true}
          text={
            currentPageResultsStart() +
            " - " +
            currentPageResultsEnd() +
            " of " +
            total +
            " Results"
          }
          className="uxf-pagination-mobile-first justify-content-center"
        >
          <Pagination.Item aria-label="previous"></Pagination.Item>
          <Pagination.Item disabled={page === 1} onClick={previousPage}>
            Previous
          </Pagination.Item>
          {pageItems()}
          <Pagination.Item disabled={page === lastPage} onClick={nextPage}>
            Next
          </Pagination.Item>
          <Pagination.Item aria-label="next"></Pagination.Item>
        </Pagination>
      )}
    </div>
  );
}
